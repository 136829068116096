import * as React from "react"
import { Link } from "gatsby"
import { PrismicProvider } from "@prismicio/react"

import { SearchModalProvider } from "./src/contexts/SearchModalContext"
import linkResolver from "./src/utils/prismicLinkResolver"
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    <SearchModalProvider>{element}</SearchModalProvider>
  </PrismicProvider>
)
