import React, { createContext, useState, useEffect } from "react"
import { globalHistory } from "@reach/router"

const SearchModalContext = createContext({
  isOpen: false,
  setIsOpen: () => {},
  query: null,
  setQuery: () => {},
})

export default SearchModalContext

export const SearchModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [query, setQuery] = useState()

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") setIsOpen(false)
    })
  }, [])

  useEffect(() => {
    if (!isOpen) setQuery(null)
  }, [isOpen])

  return (
    <SearchModalContext.Provider
      value={{
        isOpen,
        setIsOpen,
        query,
        setQuery,
      }}
    >
      {children}
    </SearchModalContext.Provider>
  )
}
