const linkResolver = doc => {
  if (doc.type === "pages") {
    return `/${doc.uid}`
  }

  if (doc.type === "article") {
    return `/${doc.uid}`
  }

  if (doc.type === "news") {
    return `/news`
  }

  if (doc.type === "contact") {
    return "/contact"
  }

  if (doc.type === "careers") {
    return "/careers"
  }

  return "/"
}

module.exports = linkResolver
